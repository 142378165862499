import React from "react";
import Layout from "../components/Layout";
import HeaderSimple from "../components/HeaderSimple";
import ModalPrivacy from "../components/ModalPrivacy";


const Whistleblowing = ({ pageContext }) => {
    const siteContent = (
        <div>
          <Layout pageContext={pageContext}>
            <HeaderSimple 
                title="Whistleblowing" 
            />
            <section className="section-press ">
                <div className="container section">
                <p>
                    <b>Gruppo Pam S.p.A.</b> (la “Società”) ha adottato, ai sensi del D. Lgs. 24/23, emanato in attuazione della Direttiva UE 2019/1937 (cd. Direttiva Whistleblowing), un ulteriore canale interno di segnalazione attraverso il quale i collaboratori (sia interni che esterni alla Società) possono segnalare, in via del tutto anonima e riservata, comportamenti, atti od omissioni, di cui siano venuti a conoscenza nello svolgimento della loro attività lavorativa, contrari a disposizioni di legge nazionali o europee o alle disposizioni del Modello di gestione, organizzazione e controllo adottato dalla Società si sensi del D.lgs 231/01 e lesivi degli interessi e/o dell’integrità della Società.<br />
                    <br />
                        La procedura di segnalazione e l’informativa privacy sono disponibili ai seguenti link:<br />
                        <a href="https://it-pampanorama-dev.s3.eu-west-3.amazonaws.com/Whistleblowing/Procedura+whistleblowing.docx" target='_blank' className="link">Procedura Whistleblowing</a><br />
                        <a href="https://it-pampanorama-dev.s3.eu-west-3.amazonaws.com/Whistleblowing/Informativa+privacy+segnalante.docx" target='_blank' className="link">Informativa Privacy</a><br />
                        <br />
                        Per effettuare una segnalazione è sufficiente cliccare sul seguente link e seguire le relative istruzioni:<br />
                        <a href="https://wbreport.kpmg.se/GruppoPAMSpa" target='_blank' className="link">Piattaforma di segnalazione</a><br />
                        <br />
                        A seguito dell’invio della segnalazione verrà fornito al segnalante un ID univoco identificativo della segnalazione che, unitamente alla password creata dallo stesso, potrà essere utilizzato per verificare lo stato di gestione della segnalazione stessa.<br />
                        Destinatario delle segnalazioni sarà, in via riservata, un Comitato interno della Società appositamente costituito, composto da soggetti qualificati.
                    </p>
                </div>
            </section>
        </Layout>
        </div>
      );
      return (
        <div>
          <ModalPrivacy content={siteContent} />
        </div>
      );
}

export default Whistleblowing